.dropdown-menu {
    background: white;
    border: 1px solid;
    width: 210px;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: left;
}

.dropdown-menu {
    background: white;
    cursor: pointer;
    padding: 0;
}

.dropdown-menu li:hover{
    background: grey;
}

.dropdown-menu.clicked {
    display: block; 
}

.dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-family: 'Raleway', san-serif;
    color: black;
    padding: 16px 25px;
}

@media screen and (max-width: 1000px){
    .dropdown-menu {
        width: 200px;
    } 
}

@media screen and (max-width: 433px){
    .dropdown-menu {
        width: 185px;
    } 
}