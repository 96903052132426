body, html{
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0 !important;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: 
  -apple-system, 
  BlinkMacSystemFont, 
  'Segoe UI', 
  'Roboto', 
  'Oxygen',
  'Ubuntu', 
  'Cantarell', 
  'Fira Sans', 
  'Droid Sans', 
  'Helvetica Neue',
  'Tauri',
  'Raleway',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
